<template>
  <div>
    <navigation :msg="msg"></navigation>
    <div class="moclass">
      <div>可提现金额（元）</div>
      <div>{{ allmoney.toLocaleString() }}元</div>
    </div>
    <div class="withdrawclass">
      <div>提现金额 （100元起提，单笔最高49999）</div>
      <div class="thewithdraw">
        <div>
          <div>¥</div>
          <div><input v-model="money" type="number" /></div>
        </div>
        <div>
          <div @click="allwithdraw">全部提现</div>
          <div class="havewithdraw">可用余额{{ havawithdraw }}元</div>
        </div>
      </div>
      <div class="nomoney">
        <div :class="maxmoney == true ? '' : 'maxmoney'">超出可用余额</div>
        <div @click="$router.push('/accountsetting')">提现设置</div>
      </div>
    </div>
    <button
      @click="withdrawMeth"
      :class="ismoney == true ? 'hoverclass' : 'but'"
    >
      预计7个工作日到账，确认申请提现
    </button>
    <div class="lixiclass">
      合伙人申请后提现到私人银行卡，将产生个人所得税税费。
      每笔收取6.72%，提现时代扣。 单笔限额5万元，平台审核打款后7个工作日内到账。
    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import { Toast } from "vant";

export default {
  components: {
    navigation,
  },
  mounted() {
    /**
     * 查询余额
     */
    this.getmoney();
  },
  data() {
    return {
      msg: "我要提现",
      money: "",
      ismoney: false, //是否输入金额
      maxmoney: false, //提现余额是否超出最大余额
      allmoney: "0.00", //可提现金额
    };
  },
  computed: {
    havawithdraw: function () {
      return this.allmoney - this.money;
    },
  },
  watch: {
    money: function (newmsg, oldmsg) {
      if (newmsg < 0) {
        this.money = this.money.replaceAll("-", "");
      }

      var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
      if (!reg.test(newmsg)) {
        if (newmsg == "") {
          this.money = "";
          return;
        }
        this.money = oldmsg;
      } else {
        this.money = newmsg;
      }

      if (this.allmoney - this.money < 0) {
        this.maxmoney = true;
      } else {
        this.maxmoney = false;
      }

      if (
        parseInt(newmsg) > 99 &&
        this.maxmoney == false &&
        this.money < 50000
      ) {
        this.ismoney = true;
      } else {
        this.ismoney = false;
      }
    },
  },
  methods: {
    /**
     * 全部提现
     */
    allwithdraw() {
      if (this.allmoney == 0) {
        this.$toast("暂无可提现余额");
        return;
      }
      this.money = this.allmoney;
    },
    /**
     * 查询渠道端余额
     */
    getmoney() {
      this.$http
        .post("/firm/v1/Channel/see_balance", {
          reqType: "userinfo",
        })
        .then((res) => {
           
          this.allmoney = JSON.parse(res.data).data.balance;
        });
    },
    clickmeth() {
       
    },

    //申请提现
    withdrawMeth() {
      if (this.ismoney == true) {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        this.$http
          .post("/firm/v1/Channel/apply_withdrawal", {
            reqType: "userinfo",
            money: this.money,
          })
          .then((res) => {
             
            this.$router.push({
              path: "withdrawsuccess",
              query: {
                id: "100000000",
              },
            });
          })
          .catch((err) => {
             
          });
      }
    },
  },
};
</script>
<style scoped>
.lixiclass {
  font-size: 0.24rem;
  line-height: 0.35rem;
  margin: 0.34rem 0.3rem;
}
.hoverclass {
  background-color: #f66624;
  color: #ffffff;
  margin: 0 0.32rem;
  font-size: 0.32rem;
  width: auto;
  border: none;
  width: 90%;
  margin: 0 5%;
  padding: 0.32rem 0.63rem;
}
.maxmoney {
  visibility: hidden;
}
.havewithdraw {
  color: #b2b2b2;
}
.nomoney {
  font-size: 0.24rem;
  color: #f65c17;
  margin: 0.23rem;
  display: flex;
  justify-content: space-between;
}
.but {
  background-color: #f5ae8e;
  color: #ffffff;
  margin: 0 0.32rem;
  font-size: 0.32rem;
  width: auto;
  border: none;
  width: 90%;
  margin: 0 5%;
  padding: 0.32rem 0.63rem;
}
.withdrawclass {
  padding: 0.48rem 0.3rem;
  padding-bottom: 1px;
  margin: 0.4rem 0.3rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
}
.withdrawclass > div:first-child {
  font-size: 0.3rem;
}
.thewithdraw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.31rem 0;
  border-bottom-color: #eeeeee;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: 0.3rem;
}
.thewithdraw input {
  max-width: 3rem;
  border: none;
}
.thewithdraw > div:first-child {
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.thewithdraw > div:nth-child(2) {
  font-size: 0.24rem;
  color: #f66624;
}
.moclass {
  font-size: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.35rem 0.3rem;
  margin: 0.37rem 0.31rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
}
.moclass > div:first-child {
  color: #222222;
  opacity: 0.35;
}
.moclass > div:nth-child(2) {
  color: #f65c17;
  font-size: 0.4rem;
}
</style>